import React from 'react'

export default function PenNib({ width = 30, height = 30 }) {
  return (
    <svg
      width={width}
      height={height}
      viewBox='0 0 30 30'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g id='PenNib'>
        <path
          id='Vector'
          d='M13.5938 18.75C14.8882 18.75 15.9375 17.7007 15.9375 16.4062C15.9375 15.1118 14.8882 14.0625 13.5938 14.0625C12.2993 14.0625 11.25 15.1118 11.25 16.4062C11.25 17.7007 12.2993 18.75 13.5938 18.75Z'
          stroke='currentColor'
          strokeWidth='1.5'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          id='Vector_2'
          d='M3.75 26.25L11.9414 18.0586'
          stroke='currentColor'
          strokeWidth='1.5'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          id='Vector_3'
          d='M3.75 26.25L20.0977 23.5313C20.259 23.5037 20.4102 23.4339 20.5357 23.3289C20.6613 23.2239 20.7568 23.0875 20.8125 22.9336L23.4375 15.9375L14.0625 6.5625L7.06641 9.1875C6.91224 9.24623 6.77604 9.34412 6.67123 9.47153C6.56643 9.59894 6.49664 9.75146 6.46875 9.91406L3.75 26.25Z'
          stroke='currentColor'
          strokeWidth='1.5'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          id='Vector_4'
          d='M23.4375 15.9375L26.5195 12.8555C26.6081 12.7683 26.6785 12.6643 26.7265 12.5496C26.7745 12.4349 26.7992 12.3118 26.7992 12.1875C26.7992 12.0632 26.7745 11.9401 26.7265 11.8254C26.6785 11.7108 26.6081 11.6068 26.5195 11.5196L18.4805 3.48049C18.3932 3.39191 18.2893 3.32156 18.1746 3.27355C18.0599 3.22553 17.9368 3.20081 17.8125 3.20081C17.6882 3.20081 17.5651 3.22553 17.4504 3.27355C17.3357 3.32156 17.2318 3.39191 17.1445 3.48049L14.0625 6.56252'
          stroke='currentColor'
          strokeWidth='1.5'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
      </g>
    </svg>
  )
}
