import cn from 'classnames'
import { useEffect, useState } from 'react'

export default function PasswordInput(props) {
  const { label, name, register, error, placeholder } = props
  const [showPassword, setShowPassword] = useState(false)
  const [inputType, setInputType] = useState('password')

  useEffect(() => {
    if (showPassword) {
      setInputType('text')
    } else {
      setInputType('password')
    }
  }, [showPassword])
  return (
    <div className={cn('w-full', 'mt-4')}>
      <label className={cn('block', 'text-sm', 'font-normal')} htmlFor={name}>
        {label}
      </label>
      <div
        className={cn(
          'flex',
          'border',
          'border-[#C1C1C1]',
          'relative w-full',
          'focus-within:border-howdy-primary-green',
          'rounded-lg',
          'pr-3',
        )}
      >
        <input
          className={cn(
            'border-none',
            'px-3 py-[15px]',
            'font-worksans',
            'text-base',
            'font-regular',
            'placeholder-default',
            'focus:placeholder-focus',
            'w-full',
            'text-howdy-black',
            'leading-[22px]',
            'focus:ring-0',
            'rounded-lg',
            'focus:text-howdy-primary-green',
          )}
          type={inputType}
          {...register}
          placeholder={placeholder}
        />
        <img
          src={showPassword ? '/Eye.svg' : '/EyeClosed.svg'}
          className={'w-[24px] cursor-pointer'}
          onClick={() => {
            setShowPassword(!showPassword)
          }}
        />
      </div>
    </div>
  )
}
