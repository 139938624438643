import { useEffect, useState } from 'react'
import useTCs from '../../hooks/useTCs'
import styles from './TermsAndConditions.module.css'

function TermsAndConditionsText({ setHtml = () => null, noHeader = false }) {
  const { tcs } = useTCs()
  const [__html, setText] = useState()

  useEffect(() => {
    if (tcs) {
      setText(tcs.text)
      setHtml(tcs.text)
    }
  }, [tcs])

  if (!__html) {
    return <div>Loading...</div>
  }

  return (
    <div
      dangerouslySetInnerHTML={{ __html }}
      className={`${styles.terms} ${noHeader ? '[&>div>h1]:hidden' : ''}`}
    />
  )
}

export default TermsAndConditionsText
