// https://jakzaizzat.com/how-to-a-build-custom-checkbox-in-tailwindcss

import React, { ChangeEventHandler } from 'react'
import { cn } from '@howdy/core/lib/utils'

function Checkbox({
  label,
  name,
  value = false,
  onChange,
  disabled = false,
  className,
}: {
  name: string
  label: string
  value: boolean
  onChange: ChangeEventHandler<HTMLInputElement>
  disabled?: boolean
  className?: string
}) {
  return (
    <label
      className={cn(`flex items-center ${disabled ? 'cursor-not-allowed' : 'cursor-pointer'}`, {
        'gap-2': label,
      })}
      onClick={(e) => e.stopPropagation()}
    >
      <div
        className={cn(
          `box-border flex h-[28px] w-[28px] items-center justify-center rounded border border-solid ${
            disabled ? 'border-howdy-disabled-dark' : 'border-black'
          } ${
            disabled
              ? 'bg-howdy-disabled'
              : value
              ? 'border-howdy-primary-green text-howdy-primary-green'
              : 'border-[#C1C1C1] text-howdy-black'
          }`,
          className,
        )}
      >
        <input
          type='checkbox'
          className={`h-full w-full rounded-sm border-none bg-transparent text-transparent ${
            disabled ? 'cursor-not-allowed' : 'cursor-pointer'
          }`}
          checked={value}
          style={{ backgroundSize: '70%' }}
          onChange={onChange}
          disabled={disabled}
          name={name}
        />
      </div>
      <div
        className={`flex-1 ${
          disabled ? 'text-howdy-disabled-dark' : value ? 'text-howdy-primary-green' : 'text-black'
        }`}
      >
        {label}
      </div>
    </label>
  )
}

export default Checkbox
