import Link from 'next/link'
import cn from 'classnames'

export default function LinkButton(props) {
  const { href, text, preText, postText, color = 'text-[#464646]' } = props

  return (
    <>
      <p className='font-worksans'>
        {preText}
        <span
          className={cn(
            'bg-transparent px-0 py-0 font-bold text-howdy-black decoration-1 underline-offset-2',
            'underline hover:text-howdy-light-green',
            color,
          )}
        >
          <Link href={href}>{text}</Link>
        </span>
        {postText}
      </p>
    </>
  )
}
